<template>
  <div class="asset-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Lịch sử di chuyển tài sản</b-card-title>

      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col>
            <select-room
              v-model="room"
              :apartment="apartment"
            />
          </b-col>
          <b-col>
            <select-warehouse v-model="warehouse" />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>

        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"

              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-asset-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'price'">
                  {{ Number(props.row.price).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'time'">
                  {{ parseDateToString(props.row.movingDate) }}
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'apartment.name'">
                  <span v-if="props.row.apartment">{{ props.row.apartment.name }}</span>
                  <span
                    v-if="props.row.room"
                    class="text-muted"
                  ><br>{{ props.row.room.name }}</span>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'default'">
                  <b-form-checkbox
                    :checked="props.row.default"
                    name="check-button"
                    switch
                    inline
                    disabled
                  />
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Tài sản"
                    modal="modal-asset"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-move-asset
                        variant="warning"
                        class="btn-icon mr-50"
                        size="sm"
                        title="Di chuyển tài sản"
                        @click="onMoveItems([props.row])"
                      >
                        <feather-icon icon="MoveIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,

  BFormCheckbox,
  BFormSelect,
  BSpinner,
  BButton,
  VBTooltip,
} from 'bootstrap-vue';

import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import SelectWarehouse from '@/views/components/SelectWarehouse.vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from '@/auth/utils';
import useAssetLogList from './useAssetLogList';
// eslint-disable-next-line import/no-cycle

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormCheckbox,
    BFormSelect,
    BSpinner,
    BButton,

    VueGoodTable,
    TableActions,
    SelectWarehouse,
    SelectApartment,
    SelectRoom,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      columns,
      rows,
      searchTerm,
      provider,
      warehouse,
      assetType,
      apartment,
      room,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      refetchAssets,
      fetchAssets,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
    } = useAssetLogList();

    return {
      columns,
      rows,
      searchTerm,
      provider,
      warehouse,
      assetType,
      apartment,
      room,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      refetchAssets,
      fetchAssets,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      parseDateToString,
    };
  },

  created() {
    this.fetchData();
  },

};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

.asset-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }

            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 150px !important;
            }
            &:nth-child(7) {
               min-width: 150px !important;
            }
            &:nth-child(8) {
               min-width: 200px !important;
            }
            &:nth-child(12) {
               min-width: 200px !important;
            }
          }
        }
      }
    }
}
</style>
