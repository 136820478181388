<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="warehouse"
        v-model="warehouse"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="warehouses"
        :disabled="!enable"
        placeholder="Chọn kho"
        @input="valueChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd === true && $can('create', 'warehouse')"
          #list-header
        >
          <li
            v-b-modal.modal-warehouse
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">Thêm kho</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <warehouse-handler
      v-if="enableAdd == true"
      :item="{}"
      @refetch-warehouses="fetchData"
      @on-item-created="onItemCreated"
    />
  </div>

</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import WarehouseHandler from '../inventory/warehouse/handler/WarehouseHandler.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    WarehouseHandler,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      warehouse: this.defaultValue,
      warehouses: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.warehouse);
    },
    fetchData() {
      useJwt.getWarehouses().then(res => {
        this.warehouses = res.data.items;
      });
    },
    onOpen() {
      if (this.warehouses.length === 0) {
        this.fetchData();
      }
    },
    onItemCreated(newItem) {
      this.warehouse = newItem;
      this.valueChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';

</style>
